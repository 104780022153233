import { render, staticRenderFns } from "./rejected-detail.vue?vue&type=template&id=5817f449&scoped=true&"
import script from "./rejected-detail.vue?vue&type=script&lang=js&"
export * from "./rejected-detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5817f449",
  null
  
)

export default component.exports