<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
    </template>

    <a-spin :spinning="loading">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">企业详情</h3>
        </div>
        <div class="module-main">
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">企业名称：</span>
                <span class="detail-value">{{detailData.companyName || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">企业类型：</span>
                <span class="detail-value">{{detailData.companyTypeText}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">营业执照：</span>
                <span class="detail-value">{{detailData.companyLicenseNumber || '-'}}</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">营业执照图片：</span>
                <a-button v-if="detailData.companyLicenseImageUrl" type="link" style="padding: 0" @click="viewBigImage">点击查看营业执照照片</a-button>
                <span class="detail-value" v-else>-</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">负责人姓名：</span>
                <span class="detail-value">{{detailData.companyChargePerson || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">负责人身份号码：</span>
                <span class="detail-value">{{detailData.companyChargePersonIdNumber || '-'}}</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">公司地址：</span>
                <span class="detail-value">{{detailData.companyAddress || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">工厂地址：</span>
                <span class="detail-value">{{detailData.factoryAddress || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">联系人员：</span>
                <span class="detail-value">{{detailData.companyKeyPerson || '-'}}</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">联系电话：</span>
                <span class="detail-value">{{detailData.companyKeyPersonPhone || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">业务类型：</span>
                <span class="detail-value">{{businessTypesStr}}</span>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
    <!-- 查看大图 -->
    <a-modal v-model="imgVisible" title="营业执照照片" centered :width="800" :footer="null">
      <img style="width: 100%;" :src="viewImgUrl">
    </a-modal>
  </page-head-wrapper>
</template>

<script>
import { companyDetail, templateList } from '@/api/company'
import { buildThumborUrl } from '@/utils/tools'
export default {
  name: 'static-data-company-rejected-detail',
  keep_alive_tab: true,
  data() {
    return {
      loading: false,

      // 查看大图
      imgVisible: false,
      viewImgUrl: '',

      // 当前路由
      id: '',
      currentPath: '',

      // 审核
      checkVisible: false,
      checkLoading: false,
      checkForm: {
        type: '',
        reason: ''
      },
      checkRules: {
        type: [
          { required: true, message: '请选择审核结果', trigger: 'change' },
        ],
        reason: [
          { required: true, message: '请输入驳回理由', trigger: 'blur' },
        ],
      },

      detailData: {
        authResult: '',

        companyName: '',
        companyType: '',
        companyTypeText: '',

        companyLicenseNumber: '',
        companyLicenseImage: '',
        companyLicenseImageUrl: '',
        
        companyChargePerson: '',
        companyChargePersonIdNumber: '',

        companyProvince: '',
        companyCity: '',
        companyDistrict: '',
        companyAddress: '',
        
        companyKeyPerson: '',
        companyKeyPersonPhone: '',

        factoryProvince: '',
        factoryCity: '',
        factoryDistrict: '',
        factoryAddress: '',
      },
      businessTypesArr: [],
      businessTypesStr: '',
      templateList: [],
    }
  },
  created() {
    this.id = this.$route.query.id
    this.currentPath = this.$route.fullPath
    this.companyDetail()
  },
  methods: {
    companyDetail() {
      this.loading = true
      companyDetail(this.id).then(res => {
        console.log(res)
        if (res.code == 200) {
          let companyTypeText = res.data.companyType == 1 ? '工商型': res.data.companyType == 2 ? '个人型' : '-'
          this.detailData = {
            ...res.data,
            companyTypeText,
            companyLicenseImageUrl:  buildThumborUrl(res.data.companyLicenseImage)
          }
          // 更新标题
          if (res.data.companyName) {
            this.$store.dispatch('updateTabTitle', {
              force: true,
              path: this.currentPath,
              title: res.data.companyName
            })
          }

          try {
            this.businessTypesArr = this.detailData.businessTypes.split(',')
          } catch (error) {
            this.businessTypesArr = []
          }

          // 获取模板数据
          if (this.businessTypesArr.length > 0) {
            this.getTemplateList()
          } else {
            this.businessTypesStr = '-'
          }
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取业务类型列表
    getTemplateList() {
      templateList({parentId: ''}).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.templateList = res.data.filter(item => item.status == 1)
          this.calcBusinessTypes()
        } else {
          this.$message.error(res.msg || '获取业务类型出错')
        }
      }).catch(err => {
        console.warn(err)
      })
    },
    // 计算企业已选择业务， id => 名称
    calcBusinessTypes() {
      let businessTypesStr = ''
      let selectedBusinessTypes = []
      if (this.businessTypesArr.length > 0 && this.templateList.length > 0) {
        console.log(this.businessTypesArr)
        selectedBusinessTypes =  this.templateList.filter(item => this.businessTypesArr.indexOf(''+item.id) >= 0 )
        businessTypesStr = selectedBusinessTypes.map(item => item.name).join('、') || '-'
      } else {
        businessTypesStr = '-'
      }
      this.businessTypesStr = businessTypesStr
    },
    // 查看营业执照大图
    viewBigImage() {
      this.imgVisible = true
      this.viewImgUrl = this.detailData.companyLicenseImageUrl
    },
    // 刷新操作
    onRefresh() {
      this.companyDetail()
    },
  }
}
</script>

<style scoped>

</style>